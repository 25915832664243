<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.lineName" placeholder="线路名称" />
        </a-form-item>
        <a-form-item label="">
          <a-cascader v-model:value="searchForm.start" :options="$store.state.app.cityDict" :show-search="{ filter }"
            :changeOnSelect="true" @change="setSearchFormStartAddress" placeholder="起点" />
        </a-form-item>
        <a-form-item label="">
          <a-cascader v-model:value="searchForm.end" :options="$store.state.app.cityDict" :show-search="{ filter }"
            :changeOnSelect="true" @change="setSearchFormEndAddress" placeholder="终点" />
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button  :loading="loading" type="primary" @click="onSearch"
              :disabled="loading">
              查询
            </a-button>
            <a-button @click="reset">
              重置
            </a-button>
          </a-space>
        </a-form-item>

      </a-form>
    </template>
    <LineListMoadl ref="lineListModalRef" @copyLine="copyLine" :loading="loading" @onEditLineShow="onEditLineShow" :data="listData" @onEditShow="onEditShow"></LineListMoadl>
    <div class="m-t1 m-b1 flex ju-end" >
      <a-pagination size="small" :show-total="pagination.showTotal" @change="handleTableChange" v-model:current="pagination.current"
      v-model:pageSize="pagination.pageSize"   :total="pagination.total" show-size-changer @showSizeChange="showSizeChange"  />
    </div>
    <!-- <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #setUp="{ record }">
        <div v-if="record.idOptimal === ''"> 当前无最优承运商 <a @click="onEditShow(record)">绑定承运商</a></div>
        <div v-else> {{ record.carrierName }} <a v-if="record.sum > 1" @click="onEditShow(record)">修改</a></div>
      </template>
      <template #prescription="{ record }">
        <div v-if="record.idOptimal !== ''"> {{ record.prescription }}</div>
      </template>
      <template #remark="{ record }">
        <div v-if="record.idOptimal !== ''">{{ record.remark }} </div>
      </template>
      <template #operation="{ record }">
        <a @click="onEditLineShow(record)">修改  </a>
        <a style="margin-left:10px" @click="copyLine(record)">复制线路</a>
      </template>
    </a-table> -->
  </HxTable>
  <a-modal v-model:visible="editShow" title="选择最优承运商" width="600px" @ok="onBindingCarrier" okText="设为最优">
    <a-form :model="searchForm" layout="vertical">
      <a-form-item label="选择该线路最优承运商">
        <a-select style="width:100%" v-model:value="editCarrier.value" :options="CarrierList" placeholder="选择最优承运商"
          @change="editCarrierChange" />
      </a-form-item>
    </a-form>
    <!--    <div style="display:flex;justify-content: flex-end;margin-top:15px">-->
    <!--      <a-button size="large" style="margin-right:15px" @click="editShow=false">取消</a-button>-->
    <!--      <a-button size="large" class="searchLoading-button" @click="addData">-->
    <!--        设为最优-->
    <!--      </a-button>-->
    <!--    </div>-->
  </a-modal>

  <a-modal v-model:visible="editLineShow" title="修改线路" footer="" width="600px" @ok="addData" :destroyOnClose="true">
    <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
      <a-form-item label="业务类型" :name="['businessType', 'value']">
        <a-select @change="onClearAddress" :disabled="lineTypeState" v-model:value="addForm.businessType.value"
          style="width:552px" :options="$store.state.enumAll.businessType" placeholder="请选择业务类型" />
      </a-form-item>
      <a-form-item label="线路类型" v-if="addForm.businessType.value !== 3" :name="['lineType', 'value']">
        <a-select @change="onLineTypeAddress" :disabled="disabled" v-model:value="addForm.lineType.value"
          :options="$store.state.enumAll.routeType" placeholder="请选择线路类型" />
      </a-form-item>
      <a-form-item label="运输类型" v-if="addForm.businessType.value === 3" :name="['transportType', 'value']">
        <a-select :disabled="disabled" v-model:value="addForm.transportType.value"
          :options="$store.state.enumAll.transportType" placeholder="请选择运输类型" />
      </a-form-item>
      <a-form-item label="取送类型" v-if="addForm.businessType.value === 3" :name="['isDelivery', 'value']">
        <a-select :disabled="disabled" v-model:value="addForm.isDelivery.value" :options="DeliveryEnum"
          placeholder="请选择取送类型" />
      </a-form-item>
      <a-form-item label="价格类型" v-if="addForm.businessType.value === 3">
        <a-select :disabled="disabled" v-model:value="addForm.lineOffer.value" @change="onLineOffer"
          :options="$store.state.enumAll.lineOfferType" placeholder="请选择价格类型" />
      </a-form-item>
      <a-form-item label="" v-if="addForm.businessType.value === 3 && addForm.lineOffer.value === 1" name="cost">
        <a-input :disabled="disabled" v-if="addForm.lineOffer.value === 1" v-model:value="addForm.cost" type="number"
          :min="1" placeholder="请填写一口价" />
      </a-form-item>
      <a-form-item label="" v-if="addForm.businessType.value === 3 && addForm.lineOffer.value === 2">
        <div class="startingPrice">
          <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.startingPrice" type="number"
            :min="1" placeholder="" />
          起步，超出
          <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.exceedingKm" type="number"
            :min="1" placeholder="" />
          公里，每公里
          <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.perKmPrice" type="number"
            :min="1" placeholder="" />
          元
        </div>
      </a-form-item>
      <a-form-item>
        <template #label><span style="color:#f00">*&nbsp;</span>线路名称</template>
        <div style="display:flex;justify-content: space-between;">
          <a-cascader v-if="addForm.businessType.value != 3" :disabled="lineTypeState" v-model:value="startAddress"
            :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true"
            @change="setStartAddress" placeholder="请选择起点" style="width:48%" />
          <a-cascader v-else :disabled="lineTypeState" v-model:value="startAddress"
            :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true"
            @change="setStartAddress" placeholder="请选择起点" style="width:552px" />
          <a-cascader :disabled="lineTypeState" v-if="addForm.businessType.value != 3" v-model:value="endAddress"
            :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true"
            @change="setEndAddress" placeholder="请选择终点" style="width:48%" />
        </div>
      </a-form-item>
      <a-form-item label="" v-if="addForm.businessType.value === 3 && addForm.lineOffer.value > 0">
        <a-checkbox-group v-model:value="value">
          <a-row :gutter="[24, 6]">
            <a-col :span="8" v-for="(item, index) in startingAddress" :key="index">
              <view style="display: flex;">
                <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>
                <a-input :disabled="disabled" v-if="value.indexOf(index) != -1 && addForm.lineOffer.value === 1"
                  style=" width: 40%;height: 26px" size="small" compact v-model:value="item.cost" type="number" :min="1"
                  placeholder="请填写一口价" />
              </view>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item label="是否设为最优线路">
        <a-select :disabled="disabled" v-model:value="addForm.isOptimal.value"
          :options="$store.state.enumAll.BooleanFlagEnum" placeholder="请选择是否设为最优线路" />
      </a-form-item>

      <a-form-item label="起点城市区"
        v-if="addForm.startArea === null && (addForm.businessType.value === 1 || addForm.businessType.value === 2) && (addForm.lineType.value === 3 || addForm.lineType.value === 4)">
        <!--          <a-checkbox-group v-model:value="startingStoreValue">-->
        <a-row :gutter="[24, 6]">
          <a-col :span="8" v-for="(item, index) in startingStoreAddress" :key="index">
            <view style="display: flex;">
              <!--                    <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>-->
              {{ item.label }}
              <a-input :disabled="disabled" style=" width: 40%;" size="small" compact v-model:value="item.cost"
                type="number" :min="1" placeholder="请填写价格" />
            </view>
            <!--                <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>-->
          </a-col>

        </a-row>
        <!--          </a-checkbox-group>-->
      </a-form-item>
      <a-form-item label="终点城市区"
        v-if="addForm.endArea === null && (addForm.businessType.value === 1 || addForm.businessType.value === 2) && (addForm.lineType.value === 2 || addForm.lineType.value === 4)">
        <a-checkbox-group v-model:value="endStoreValue">
          <a-row :gutter="[24, 6]">
            <a-col :span="8" v-for="(item, index) in endStoreAddress" :key="index">
              <!--                <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>-->
              {{ item.label }}
              <a-input :disabled="disabled" style=" width: 40%;" size="small" compact v-model:value="item.cost"
                type="number" :min="1" placeholder="请填写价格" />
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item label="报价" name="cost" v-if="addForm.businessType.value != 3">
        <a-input :disabled="disabled" v-model:value="addForm.cost" type="number" :min="1" placeholder="请填写报价" />
      </a-form-item>
      <a-form-item label="地跑价格" name="groundCost" v-if="addForm.businessType.value === 1">
        <a-input :disabled="disabled" v-model:value="addForm.groundCost" type="number" :min="1" placeholder="请填写地跑价格" />
      </a-form-item>
      <a-form-item label="公里数" name="km" v-if="addForm.businessType.value != 3">
        <a-input :disabled="disabled" v-model:value="addForm.km" type="number" :min="1" placeholder="请填写公里" />
      </a-form-item>
      <a-form-item label="时效" name="prescription">
        <a-input :disabled="disabled" v-model:value="addForm.prescription" type="number" :min="1" placeholder="请填写时效" />
      </a-form-item>
      <a-form-item label="线路标签">
        <a-select :disabled="disabled" v-model:value="addForm.lineLabel" @change="onLineLabel"
          :options="$store.state.enumAll.Label" placeholder="请选择线路标签" />
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea :disabled="disabled" v-model:value="addForm.remark" />
      </a-form-item>
    </a-form>
    <div style="display:flex;justify-content: flex-end;margin-top:15px">
      <a-button size="large" style="margin-right:15px" @click="editLineShow = false">取消</a-button>
      <a-button size="large" class="searchLoading-button" @click="addData">
        确认修改
      </a-button>
    </div>
  </a-modal>
  <a-modal v-model:visible="carrierChooseShow" title="复制线路" @ok="confirmCopy" :confirmLoading="loading" >
    <div>
      <div style="color:#f00;font-size: 12px;">注：搜索并选择承运商单击确认即可复制线路</div>
      <a-select v-if="carrierChooseShow" v-model:value="carrierId" style="width:100%" placeholder="搜索承运商" @search="fetchCarrier"
        :filterOption="false" show-search>
        <template v-if="fetching" #notFoundContent>
          <a-spin size="small" />
        </template>
        <a-select-option v-for="item in carriers" :key="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import HxTable from '@/components/HxTable'
import { optimalPage, optimalEdit, detail, edit,copyLine as copyLineData } from '@/api/carrier/line'
import  LineListMoadl from './lineListModal'
import { fuzzy } from '@/api/crm/carrer/carrier'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'

export default {
  components: {
    HxTable,
    LineListMoadl
  },
  setup () {
    const formRef = ref()
    const store = useStore()
    const state = reactive({
      lineListModalRef: null,
      editID: {},
      editShow: false,
      editLineShow: false,
      lineTypeState: true,
      fetching: false,
      loading: true,
      timeOutId: null,
      carrierChooseShow: false,
      cityList: store.state.app.cityTypeDict,
      carrierId: '',
      lineId: '',
      carriers: [],
      listData: [],
      editCarrier: {},
      carrierData: {},
      CarrierList: [],
      startAddress: [],
      endAddress: [],
      startingStoreAddress: [],
      endStoreAddress: [],
      routeList: [],
      addForm: {
        isOptimal: {
          value: 0
        },
        lineLabel:'',
        lineLabelName:'',
        cost: '',
        remark: '',
        groundCost:'',
        prescription: '',
        startAddress: {},
        endAddress: {},
        businessType: { value: 0 },
        lineOffer: {},
        transportType: {},
        isDelivery: {},
        lineType: {}
      },
      searchForm: {
        startAddress: {},
        endAddress: {},
        businessType: {},
        charge: '',
        chargeMobile: ''
      },
      columns: [
        {
          title: '线路名称',
          dataIndex: 'lineName',
          align: 'center'
        },
        {
          title: '承运商总数量',
          dataIndex: 'sum',
          align: 'center',
          width: '8%'
        },
        {
          title: '最优承运商',
          dataIndex: 'setUp',
          align: 'center',
          slots: {
            customRender: 'setUp'
          }
        },
        {
          title: '报价',
          dataIndex: 'cost',
          align: 'center'
        },
        {
          title: '时效',
          dataIndex: 'prescription',
          align: 'center',
          slots: {
            customRender: 'prescription'
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center',
          slots: {
            customRender: 'remark'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      rules: {
        businessType: {
          value: {
            required: true,
            message: '请选择业务类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        lineType: {
          value: {
            required: true,
            message: '请选择线路类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        isDelivery: {
          value: {
            required: true,
            message: '请选择取送类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        transportType: {
          value: {
            required: true,
            message: '请选择运输类型',
            trigger: 'blur',
            type: 'number'
          }
        },
        cost: {
          required: true,
          message: '请填写一口价',
          trigger: 'blur'
        },
        km: {
          required: true,
          message: '请填写公里数',
          trigger: 'blur'
        },
        startingPrice: {
          required: true,
          message: '请填写公式价',
          trigger: 'blur'
        },
        prescription: {
          required: true,
          message: '请填写时效',
          trigger: 'blur'
        }
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const onLineLabel = (e,v) => {
      state.addForm.lineLabelName = v.label
    }
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      optimalPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
        }
      }).catch(err => {
        console.log('分页错误   ' + err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, size,) => {
      state.pagination.current = pag
      state.pagination.pageSize = size
      loadData()
    }
    const showSizeChange = (pag,size) => {
      state.pagination.current = pag
      state.pagination.pageSize = size
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {
        startAddress: {},
        endAddress: {},
        businessType: {},
        charge: '',
        chargeMobile: ''
      }
      loadData()
    }
    // 打开绑定承运商面板
    const onEditShow = (record) => {
      state.editShow = true
      state.CarrierList = []
      state.carrierData.idOptimal = record.idOptimal
      state.carrierData.lineName = record.lineName
      const carrierIdList = record.carrierIdList.split(',')
      for (let i = 0; i < carrierIdList.length; i++) {
        if (carrierIdList[i].split('-')[0] !== record.idOptimal) {
          state.CarrierList.push({
            label: carrierIdList[i].split('-')[1],
            value: carrierIdList[i].split('-')[0]
          })
        }
      }
    }
    // 绑定承运商
    const onBindingCarrier = () => {
      state.carrierData.type = 1
      state.carrierData.id = state.editCarrier.value
      state.carrierData.carrierName = state.editCarrier.label
      state.editCarrier = {}
      optimalEdit(state.carrierData).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.editShow = false
          loadData()
        }
      })
    }
    onMounted(() => {
      loadData()
    })
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress.province = value[0]
      if (value.length > 1) {
        state.searchForm.startAddress.city = value[1]
      } else {
        state.searchForm.startAddress.city = null
      }
      if (value.length > 2) {
        state.searchForm.startAddress.area = value[2]
      } else {
        state.searchForm.startAddress.area = null
      }
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress.province = value[0]
      if (value.length > 1) {
        state.searchForm.endAddress.city = value[1]
      } else {
        state.searchForm.endAddress.city = null
      }
      if (value.length > 2) {
        state.searchForm.endAddress.area = value[2]
      } else {
        state.searchForm.endAddress.area = null
      }
    }
    const editCarrierChange = (v, l) => {
      state.editCarrier = l
    }
    // // 模糊搜索
    // const filter = (inputValue, path) => {
    //   console.log('inputValue, path',inputValue, path)
    //   return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    // }
    // 搜索终起点城市
    const setSearchFormStartAddress = (value, selectedOptions) => {
      state.searchForm.startProvince = value[0]
      if (selectedOptions.length > 1) {
        state.searchForm.startCity = value[1]
      } else {
        state.searchForm.startCity = null
      }
      if (selectedOptions.length > 2) {
        state.searchForm.startArea = value[2]
      } else {
        state.searchForm.startArea = null
      }
    }
    // 搜索终点城市
    const setSearchFormEndAddress = (value, selectedOptions) => {
      state.searchForm.endProvince = value[0]
      // state.searchForm.endProvinceName = selectedOptions[0].label
      if (selectedOptions.length > 1) {
        state.searchForm.endCity = value[1]
        // state.searchForm.endCityName = selectedOptions[1].label
      } else {
        state.searchForm.endCity = null
        // state.searchForm.endCityName = null
      }
      if (selectedOptions.length > 2) {
        state.searchForm.endArea = value[2]
        state.searchForm.endAreaName = selectedOptions[2].label
      } else {
        state.searchForm.endArea = null
        // state.searchForm.endAreaName = null
      }
    }
    // 打开编辑线路
    const onEditLineShow = (record,item) => {
      console.log('干线路线', record,item)
      // if (record.idOptimal == '') {
      //   message.error('当前无最优承运商请先选择最优承运商')
      // } else {
        state.editVal = item
        state.editLineShow = true
        detail(record.id).then(res => {
          if (res.code === 10000) {
            console.log('res', res)
            state.addForm = res.data
            state.startAddress = [state.addForm.startProvince + '', state.addForm.startCity + '', state.addForm.startArea + '']
            state.endAddress = [state.addForm.endProvince + '', state.addForm.endCity + '', state.addForm.endArea + '']
            console.log('   state.addForm', state.addForm.businessType)
            state.routeList = res.data.passPoint
            if (state.addForm.businessType.value === 3) {
              onCityList(state.addForm.startProvince + '', state.addForm.startCity + '')
            }
            if (state.addForm.businessType.value === 1 || state.addForm.businessType.value === 2) {
              if (state.addForm.lineType.value === 3 || state.addForm.lineType.value === 4) {
                // onStartStoreCityList(state.addForm.startProvince + '', state.addForm.startCity + '')
                state.startingStoreAddress = []
                state.addForm.startingStoreSubLines.forEach(d => {
                  state.startingStoreAddress.push({
                    code: d.endArea,
                    cost: d.cost,
                    label: d.endAreaName,
                    value: d.endArea
                  })
                })
              }
              if (state.addForm.lineType.value === 2 || state.addForm.lineType.value === 4) {
                // onEndStoreCityList(state.addForm.endProvince + '', state.addForm.endCity + '')
                state.endStoreAddress = []
                state.addForm.endStoreSubLines.forEach(d => {
                  state.endStoreAddress.push({
                    code: d.endArea,
                    cost: d.cost,
                    label: d.endAreaName,
                    value: d.endArea
                  })
                })
              }
              state.startingAddress = []
              state.value = []
            }
            if (state.addForm.lineOffer === null) {
              state.addForm.lineOffer = {
                value: ''
              }
            }
            if (state.addForm.transportType === null) {
              state.addForm.transportType = {
                value: ''
              }
            }
            if (state.addForm.isDelivery === null) {
              state.addForm.isDelivery = {
                value: ''
              }
            }
            if (state.addForm.isOptimal === null) {
              state.addForm.isOptimal = {
                value: 0
              }
            }
          }
        }).catch(err => {
          console.log('查看详情错误' + err)
        })
      // }

    }
    // 遍历城市获取区
    const onCityList = (p, c) => {
      if (state.cityList.length < 1) {
        state.cityList = store.state.app.cityTypeDict
      }
      console.log('    state.cityList', state.cityList)
      state.startingAddress = []
      state.cityList.forEach(e => {
        if (e.value === p) {
          e.children.forEach(d => {
            if (d.value === c) {
              state.startingAddress = state.startingAddress.concat(d.children)
              e.cost = ''
            }
          })
        }
      })
      state.value = []
      if (state.addForm.subLines != null && state.addForm.subLines.length > 0) {
        state.startingAddress.forEach((e, index) => {
          for (let i = 0; i < state.addForm.subLines.length; i++) {
            if (e.value === state.addForm.subLines[i].endArea + '') {
              e.cost = state.addForm.subLines[i].cost
              state.value.push(index)
            }
          }
        })
      }
    }
    // 遍历城市获取区
    const onEndStoreCityList = (p, c) => {
      if (state.cityList.length < 1) {
        state.cityList = store.state.app.cityTypeDict
      }
      if (state.addForm.lineType.value === 2 || state.addForm.lineType.value === 4) {
        state.endStoreAddress = []
        state.cityList.forEach(e => {
          if (e.value === p) {
            e.children.forEach(d => {
              if (d.value === c) {
                d.children.cost = ''
                state.endStoreAddress = state.endStoreAddress.concat(d.children)
              }
            })
          }
        })
      }
    }
    // 遍历城市获取区
    const onStartStoreCityList = (p, c) => {
      if (state.cityList.length < 1) {
        state.cityList = store.state.app.cityTypeDict
      }
      if (state.addForm.lineType.value === 3 || state.addForm.lineType.value === 4) {
        state.startingStoreAddress = []
        state.cityList.forEach(e => {
          if (e.value === p) {
            e.children.forEach(d => {
              if (d.value === c) {
                d.children.cost = ''
                state.startingStoreAddress = state.startingStoreAddress.concat(d.children)
              }
            })
          }
        })
      }
    }
    // 干线类型切换
    const onLineTypeAddress = () => {
      if (state.addForm.endCity !== null) {
        onEndStoreCityList(state.addForm.endProvince + '', state.addForm.endCity + '')
      }
      if (state.addForm.startCity !== null) {
        onStartStoreCityList(state.addForm.startProvince + '', state.addForm.startCity + '')
      }
    }
    // 表单确认
    const addData = () => {
      formRef.value.validate().then(() => {
        if (state.addForm.lineOffer && state.addForm.lineOffer.value === 2) {
          if (state.addForm.startingPrice === undefined || state.addForm.startingPrice === '' || state.addForm.exceedingKm === undefined || state.addForm.exceedingKm === '' || state.addForm.perKmPrice === undefined || state.addForm.perKmPrice === '') {
            message.error('请填写公式价的内容')
            return
          }
        }
        if (Number(state.addForm.startCity) === Number(state.addForm.endCity)) {
          if (state.addForm.startCityName.indexOf('市') > 0 && state.addForm.businessType.value !== 3) {
            message.error('业务类型该为同城')
            return
          }
        }

        if (state.addForm.endCity !== '' && state.addForm.endCity !== undefined && state.addForm.startCity !== '' && state.addForm.startCity !== undefined) {
          state.addForm.passPoint = state.routeList
          const subLines = []
          const startingStoreSubLines = []
          const endStoreSubLines = []
         if( state.value){
          state.value.forEach(e => {
            if (e != null) {
              const d = state.startingAddress[e]
              const data = {
                cost: d.cost,
                endArea: d.value,
                endAreaName: d.label,
                endCity: state.addForm.startCity,
                endCityName: state.addForm.startCityName,
                endProvince: state.addForm.startProvince,
                endProvinceName: state.addForm.startProvinceName,
                remark: ''
              }
              subLines.push(data)
            }
          })
         }
          state.startingStoreAddress.forEach(e => {
            if (e != null) {
              // const d = state.startingStoreAddress[e]
              const data = {
                cost: e.cost,
                endArea: e.value,
                endAreaName: e.label,
                endCity: state.addForm.startCity,
                endCityName: state.addForm.startCityName,
                endProvince: state.addForm.startProvince,
                endProvinceName: state.addForm.startProvinceName,
                remark: ''
              }
              startingStoreSubLines.push(data)
            }
          })
          state.endStoreAddress.forEach(e => {
            if (e != null) {
              // const d = state.endStoreAddress[e]
              const data = {
                cost: e.cost,
                endArea: e.value,
                endAreaName: e.label,
                endCity: state.addForm.endCity,
                endCityName: state.addForm.endCityName,
                endProvince: state.addForm.endProvince,
                endProvinceName: state.addForm.endProvinceName,
                remark: ''
              }
              endStoreSubLines.push(data)
            }
          })
          if (state.addForm.businessType.value === 3) {
            if (subLines.length < 1) {
              message.error('同城至少选择一个区')
              return
            }
            state.addForm.subLines = subLines
            state.addForm.startingStoreSubLines = []
            state.addForm.endStoreSubLines = []
            state.addForm.lineType = {}
          }
          // if (state.addForm.businessType.value === 1) {
          //   state.addForm.subLines = []
          //   state.addForm.startingStoreSubLines = []
          //   state.addForm.endStoreSubLines = []
          // }
          if (state.addForm.businessType.value === 1 || state.addForm.businessType.value === 2) {
            state.addForm.subLines = []
            if (state.addForm.lineType.value === 3 || state.addForm.lineType.value === 4) {
              state.addForm.startingStoreSubLines = startingStoreSubLines
            } else {
              state.addForm.startingStoreSubLines = []
            }
            if (state.addForm.lineType.value === 2 || state.addForm.lineType.value === 4) {
              state.addForm.endStoreSubLines = endStoreSubLines
            } else {
              state.addForm.endStoreSubLines = []
            }
          }
          if (state.addForm.startArea !== null) {
            state.addForm.startingStoreSubLines = null
          }
          if (state.addForm.endArea !== null) {
            state.addForm.endStoreSubLines = null
          }
          if (state.addForm.lineOffer !== null && state.addForm.lineOffer.value === 2) {
            state.addForm.subLines.forEach(e => {
              e.cost = null
            })
          }
          console.log(state.addForm);
          edit(state.addForm.carrierId, { ...state.addForm }).then(res => {
            if (res.code === 10000) {
              message.success(res.data)
              state.editLineShow = false
              // loadData()
              state.lineListModalRef.openItem(state.editVal)
            }
          }).catch(err => {
            console.log('编辑错误' + err)
          })
        } else {
          message.error('起始地选择不正确或未选择,请检查后再次提交')
        }
      })
    }
    const copyLine = (record,item) => {
      state.editVal = item
      state.lineId = record.id
      state.carrierId = ''
      state.carriers = []
      state.carrierChooseShow = true
    }
    const fetchCarrier = value => {
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzy({ name: value }).then(res => {
            if (res.code === 10000) {
              state.carriers = res.data
            } else state.carriers = []
            state.fetching = false
          })
        }, 1000)
      } else {
        state.carriers = []
      }
    }
    const confirmCopy = () => {
      state.loading = true
      copyLineData({
        carrierId: state.carrierId,
        carrierLineId: state.lineId
      }).then(res => {
        if (res.code === 10000) {
          state.carrierChooseShow = false
          message.success('线路复制成功')
          state.carrierId = ''
          state.lineId = ''
          // loadData()
          state.lineListModalRef.openItem(state.editVal)
        }
      }).finally(() => { state.loading = false })
    }
    return {
      ...toRefs(state),
      reset,
      copyLine,
      onSearch,
      loadData,
      confirmCopy,
      fetchCarrier,
      handleTableChange,
      onEditShow,
      onBindingCarrier,
      setStartAddress,
      setEndAddress,
      // filter,
      editCarrierChange,
      setSearchFormStartAddress,
      onEditLineShow,
      formRef,
      onCityList,
      onEndStoreCityList,
      onStartStoreCityList,
      onLineTypeAddress,
      addData,
      setSearchFormEndAddress,
      showSizeChange,
      onLineLabel
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
